import * as React from "react";
import {Link} from "gatsby";
import "./styles.scss";

export default function Banner({
                                   image,
                                   text,
                                   location,
                                   isOdd,
                                   newWindow,
                                   newClass,
                                   style,
                               }) {
    return (
        <div className={`banner ${style} ${isOdd ? "odd" : "even"} ${newClass ? newClass : ''}`}>
            {location &&
                <Link
                    to={location}
                    target={newWindow ? "_blank" : "_self"}>
                    <img
                        src={image}
                        alt={text}
                    />

                    <p className="text">
                        {text}
                    </p>
                </Link>
            }

            {!location &&
                <>
                    <img
                        src={image}
                        alt={text}
                    />

                    <p className="text">
                        {text}
                    </p>
                </>
            }
        </div>
    );
}
