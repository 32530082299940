import * as React from "react"

export default function InstaFeed() {
    return (
        <div className="insta-feed-wrapper">
            <div id="curator-feed-default-feed-layout">
                <a href="https://curator.io"
                   target="_blank" className={"crt-logo crt-tag"}>
                    <img src="images/temp-insta-placeholder.png" alt=""/>
                </a>
            </div>
        </div>
    )
}
