import React, {useEffect, useState} from "react";
import Layout from "../_components/Layout/Layout";
import Banner from "../_components/Banner/Banner";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";
import InstaFeed from "../_components/InstaFeed/InstaFeed";
import ShareActionBlock from "../_components/ShareActionBlock/ShareActionBlock";
import Qr from "../_utils/Qr";

const Home = () => {
    const [isIOS, setIsIOS] = useState(true);

    function iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            || ((navigator.userAgent.includes("Mac")||(navigator.userAgent.includes("iPhone"))) && "ontouchend" in document);
    }

    useEffect(() => {
        setIsIOS(iOS());
    }, []);

    return (
        <Layout title="Welcome" hideSubNav={false}>
            <div className="video-wrapper">
                {isIOS?
                    (<div className="main-image-cover-background">
                        <img src="/images/glide/main-image-bg.jpg" alt="ios main bg"/>
                    </div>):
                    (<video className="video-background" autoPlay="autoplay" playsInline muted loop>
                        <source src="/video.mp4" type="video/mp4"/>
                    </video>)}

                <div className="title-wrapper">
                    <MainTitle>
                        Come Glide <br/>with us at Battersea <br/>power station
                    </MainTitle>
                </div>

                <div class="video-overlay"></div>
            </div>

            <ClaimRewardBtn />

            <Banner
                image="/images/glide/gifts-and-christmas-treat.jpg"
                text="GIFTS AND CHRISTMAS TREATS"
                location="/event-promotions"
            />

            <Banner
                image="/images/glide/let_us_know_what_you_think.jpg"
                text="Let us know what you think"
                location="https://xela.co/s/glc_out_review"
                newWindow={true}
                style={'left-image'}
            />

            <Banner
                image="/images/glide/whats_on.jpg"
                text="WHAT'S ON"
                location="https://xela.co/s/glc_out_on"
                newWindow={true}
            />

            <Banner
                image="/images/glide/shop-with-jo-malone.jpg"
                text="Shop with Jo Malone"
                location="https://xela.co/s/glc_out_jo_malone"
                newWindow={true}
            />

            <Banner
                image="/images/glide/take_our_survey.jpg"
                text="TAKE OUR SURVEY"
                location="https://xela.co/s/glc_out_survey"
                newWindow={true}
            />

            <InstaFeed></InstaFeed>

        </Layout>
    );
};
export default Home;
